<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h1 class="m-0 mb-2">Treatments</h1>
      <router-link
        to="/admin/treatments/create"
        class="btn btn-outline-blue py-2"
      >
        <icon type="plus" /> Add New Treatment
      </router-link>
    </div>

    <div class="card theme-card border-lg-0">
      <div class="card-body px-2 px-sm-4">
        <div class="row">
          <div class="col-12 col-md-5 mb-3 d-flex align-items-center">
            <label class="col-4 col-sm-auto col-form-label"> Service: </label>
            <multiselect
              class="app-select"
              v-model="selectedService"
              :options="dropdownServices"
              placeholder="Search for Service"
              Value="value"
              label="label"
              track-by="value"
            >
            </multiselect>
          </div>

          <div class="col-12 col-md-5 mb-3 d-flex align-items-center">
            <label class="col-4 col-sm-auto col-form-label">
              Booking Availability:
            </label>
            <select v-model="availableForBooking" class="form-control">
              <option
                v-for="option in availableForBookingOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="position-relative mb-0">
          <input
            type="search"
            class="form-control pr-5"
            @keydown.enter="applySearch"
            v-model.trim="searchContent"
            placeholder="Search by Name and Description"
          />
          <span>
            <i
              class="fa fa-search search-icon text-black"
              @click="applySearch"
            ></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="(searchContent = ''), applySearch()"
            ></i>
          </span>
        </div>
      </div>
      <alert v-if="treatmentsLoading" class="light-shadow mx-4 mb-4" />
      <alert
        class="mx-4 mb-4"
        :hideLoader="true"
        v-else-if="treatments.length == 0"
      >
        No Treatments To List, Create New One Using Add Button.
      </alert>
      <div
        class="
          card
          mb-0
          theme-card
          mobile-tablet-no-card
          p-2 p-md-0
          table-container
          border-lg-0
        "
        v-else
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Service</th>
              <th>Treatment Length</th>
              <th>Scheduled Length</th>
              <th>Price</th>
              <th>Available For Booking</th>
              <th v-if="$can({ key: 'services', expectedPermission: 'edit' })">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="treatment in treatments" :key="treatment.id">
              <td>
                <b class="mobile-tablet-only mr-2">Name:</b>{{ treatment.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Service:</b>
                {{ treatment.service.name || "-" }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Treatment Length:</b>
                {{ treatment.readable_duration }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Scheduled Length:</b>
                {{ treatment.readable_scheduled_duration }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Price:</b>
                {{ treatment.readable_price }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Available For Booking:</b>
                <span
                  class="font-weight-bold"
                  :class="
                    treatment.available_for_booking
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ treatment.available_for_booking ? "Yes" : "No" }}
                </span>
              </td>
              <td class="td-actions">
                <div
                  class="d-flex justify-content-center justify-content-xl-start"
                >
                  <router-link
                    :to="`/admin/treatments/${treatment.id}`"
                    class="btn btn-sm btn-black"
                  >
                    Edit
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm ml-3"
                    @click="deleteTreatmentHandler(treatment)"
                  >
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="deleteTreatmentLoading == treatment.id"
                    ></i>
                    <icon v-else type="trash-alt" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      latestSearch: "",
      searchContent: "",
      selectedService: null,
      availableForBooking: -1,
      availableForBookingOptions: [
        { label: "All", value: -1 },
        { label: "Available", value: 1 },
        { label: "Not Available", value: 2 },
      ],
    };
  },
  mounted() {
    this.getActiveServices();
    this.getTreatmentsHelper();
  },
  watch: {
    selectedService() {
      this.getTreatmentsHelper();
    },
    availableForBooking() {
      this.getTreatmentsHelper();
    },
  },
  computed: {
    ...mapState({
      treatments: (state) => state.treatments.treatments.data,
      treatmentsLoading: (state) => state.treatments.treatments.loading,
      activeServices: (state) => state.services.activeServices.data,
      activeServicesLoading: (state) => state.services.activeServices.loading,
      currentTreatment: (state) => state.treatments.treatment,
      currentTreatmentLoading: (state) => state.treatments.treatmentLoading,
      deleteTreatmentLoading: (state) =>
        state.treatments.deleteTreatmentLoading,
    }),
    dropdownServices() {
      return this.activeServices.map((service) => ({
        label: service.name,
        value: service.id,
      }));
    },
  },
  methods: {
    ...mapActions({
      getTreatments: "treatments/getTreatments",
      getTreatment: "treatments/getTreatment",
      getActiveServices: "services/getActiveServices",
      deleteTreatment: "treatments/deleteTreatment",
    }),
    getTreatmentsHelper() {
      const filters = {
        search: this.latestSearch,
        serviceId: this.selectedService ? this.selectedService.value : null,
        availableForBooking: this.availableForBooking,
      };
      this.getTreatments(filters);
    },
    applySearch() {
      if (this.latestSearch == this.searchContent) {
        return;
      }
      this.latestSearch = this.searchContent;
      this.getTreatmentsHelper();
    },
    deleteTreatmentHandler(treatment) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const result = await vm.deleteTreatment(treatment.id);
            if (result) {
              vm.getTreatmentsHelper();
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
}
.cancel-icon,
.search-icon {
  z-index: 10;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
</style>
